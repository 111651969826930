import React, { useState, useRef, useEffect } from 'react';
import { useCallback } from "react";
import Cookies from 'js-cookie';
import { db } from "../firebase"; 
import './design-qrcode.css';
import { FaLink, FaCarSide, FaRegIdBadge } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BsCalendar2Event } from "react-icons/bs";
import Lodinging from "../components/lodinging";  
import SwitchComponent from "../components/SwitchComponent";
import { QRCodeCanvas } from "qrcode.react";
import { CiSaveDown2 } from "react-icons/ci";
import { BsCartPlus } from "react-icons/bs";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";
import Designmenu from "./design_menu"; 
export default function Shorturl() {
  return (
    
    <div>
  
   <Helmet>
        <title>Short URL Generator | CallQR | Create Short Links Instantly</title>
        <meta name="description" content="Easily create and manage short URLs with CallQR. Generate free custom short links for websites, marketing campaigns, and business use. Track clicks and optimize engagement." />
        <meta name="keywords" content="Short URL Generator, Free Link Shortener, Create Short Links, Custom URL Shortener, Shorten URLs Instantly, QR Code with Short URL, CallQR Short Links, Track Clicks, Optimize Links, URL Redirect" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Short URL Generator | CallQR | Create Short Links Instantly"/>
        <meta property="og:description" content="Easily create and manage short URLs with CallQR. Generate free custom short links for websites, marketing campaigns, and business use. Track clicks and optimize engagement."/>
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/short-url" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta property="og:dateModified" content="2025-04-03" />
      </Helmet>  
  <Designmenu/>
 <div className="design_container_roots">
      <h1 className="text-3xl font-bold mb-4">Short URL Generator</h1>
        <p className="text-lg mb-6">Shorten your long URLs instantly and track their performance.</p>
        <input type="text" placeholder="Enter your long URL" className="border rounded p-2 w-3/4" />
      </div>  
</div>
    );
}
