import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {db, auth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from '../firebase';
import './phonelogin.css';
import Select from "react-select";
import { doc, query, where, getDocs, setDoc, getDoc, collection } from "firebase/firestore";
import Lodinging from "../components/lodinging"; 
export default function Phonelogin() {
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState('');
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState(null); 
    const [countryname, setSelectedcountryname] = useState(null); 
    
   const [loading, setLoading] = useState(false);
   const deviceName="Web";

    const setupRecaptcha = () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
        }
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
                console.log('reCAPTCHA solved:', response);
            }
        });
    };
 
    const sendOTP = async () => {
        try {
            if (!selectedCode) {
              //setError("Please select a country code.");
                return;
            }
            if (phone.length < 8) {
              setError("Enter a valid phone number.");
                return;
            }
            setLoading(true);
            document.querySelector('.input_textbox'); 
            let fullPhoneNumber = `${selectedCode.value}${phone}`;
            console.log("Sending OTP to:", fullPhoneNumber);
             setupRecaptcha();
            
             const result = await signInWithPhoneNumber(auth, fullPhoneNumber, window.recaptchaVerifier);
             setConfirmationResult(result);
            setError('OTP sent to '+fullPhoneNumber);
            document.querySelector('.phone-login-button').style.display = "none"; 
            document.querySelector('.otp-body-div').style.display = "block"; 
            document.querySelector('.codelist-input').style.display = "none"; 
            document.querySelector('.phone-input').disabled = true;

            try {
            await new Promise(resolve => setTimeout(resolve, 2000));
        } catch (error) {
            console.error("Error sending OTP:", error);
        } finally {
            setLoading(false); // Hide loader after request completes
        }
        } catch (error) {
            console.error('Error sending OTP:', error.message);
            setError(error.message);
            setLoading(false);
        }
    };


 
    const verifyCode = async () => {
        let fullPhoneNumber = `${selectedCode.value.replaceAll("+","")}${phone}`;
        try {
            if (!confirmationResult) {
                setError("Please request OTP first.");
                return;
            }
            document.querySelector('.otp-Verify_button').textContent="Verify"; 
            const result = await confirmationResult.confirm(code);
            console.log('User signed in:', result.user);
            setError('Phone verified!');
         // Set cookies for user session
       
         const accountCollection = collection(db, "account");

            // ✅ Query Firestore for the phone number
            const q = query(accountCollection, where("phone", "==", fullPhoneNumber));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const uid = generateRandomString(16);  
                const firstDoc = querySnapshot.docs[0];
                const account_Doc = firstDoc.data();


                const account_loginRef = doc(db, "account_login", uid);
       
                await setDoc(account_loginRef, {
                    guid: account_Doc.guid,
                    uid:uid,
                    phoneNumber: result.user.phoneNumber,
                    loginkey: result.user.uid,
                    device: deviceName,
                    date_time: new Date() 
                });

                Cookies.set("guid", account_Doc.guid, { expires: 365 });
                Cookies.set("loginkey", result.user.uid, { expires: 365 });
          
            } else {
                const yearFormat = new Date().getFullYear();  // Get current year
             // Assuming a function to generate OTP
                const random_number = RandomnumString(4);  // Assuming a function to generate a 4-digit random number
                const callqr_number = `${yearFormat}${code}${random_number}`;
                // ✅ If user doesn't exist, create new user record
                const guid = generateRandomString(16);   
                const newuserRef = doc(db, "account", guid);  // Set document ID as guid

                const mapdata = {
                    syncid: generateRandomString(13),
                    guid: guid,
                    name: fullPhoneNumber,
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    profile_status: true,
                    account_delete_status: false,
                    gender: "Not specified",
                    
                    blood_group: "Not specified",
                    blood_group_status: true,
                    name_date_time: new Date(),
                
                    about: "Available",
                    photo: "https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/files%2Fphotos_icon.png?alt=media&token=6ceb2e92-3dc5-41a2-8901-8467b68c6822",
                    photo_date_time: new Date(),
                    phone: fullPhoneNumber,
                    code: selectedCode.value.replaceAll("+",""),
                    otp: code,
                    phone_date_time: new Date(),
                    office_phone: "",
                    category: "user",
                    callqr_number: callqr_number,
                    referral_code: "no",
                    referral_earn: 0,
                    referral_status: true,
                
                    // App settings
                    app_auto_update: true,
                    app_update_mobile_data: true,
                    app_notified_updates: true,
                    app_version: "1.5.0",
                    token: "null",
                
                    currency_symbol: "₹",
                    currency: "INR",
                    email_status: true,
                    email: "",
                    email_date_time: new Date(),
                
                    executive_guid: "",
                    device: deviceName,
                    device_id: deviceName,
                    date_of_birth: new Date(),
                    date_of_birth_status: true,
                    address: "",
                    address_verification_status: false,
                    areas: "",
                    city: "",
                    zipcode: "",
                    state: "",
                    country: countryname,
                    address_time: new Date(),
                
                    loginkey: result.user.uid,
                    status: true,
                    login_date_time: new Date(),
                    date_time: new Date(),
                };
                
                // Save to Firestore
                await setDoc(newuserRef, mapdata);

                const account_loginRef = doc(db, "account_login", guid);
       
                await setDoc(account_loginRef, {
                    guid: guid,
                    uid: guid,
                    phoneNumber: fullPhoneNumber,
                    loginkey: result.user.uid,
                    device: deviceName,
                    date_time: new Date() 
                });

                Cookies.set("guid", guid, { expires: 90 });
                Cookies.set("loginkey", result.user.uid, { expires: 90 });

                console.log("✅ New user data saved to Firestore.");
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }


        } catch (error) {
            console.error('Error verifying code:', error.message);
            setError("Error verifying code");
        }
    };
  
    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
      function RandomnumString(length) {
        const characters = '123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
    useEffect(() => {
        const fetchPhoneCodes = async () => {
            try {
                const response = await fetch("https://restcountries.com/v3.1/all");
                const data = await response.json();

                const codes = data
                    .map((country) => ({
                        label: `${country.name.common} (${country.idd?.root || ''}${country.idd?.suffixes ? country.idd.suffixes[0] : ''})`,
                        value: `${country.idd?.root || ''}${country.idd?.suffixes ? country.idd.suffixes[0] : ''}`,
                    }))
                    .filter((code) => code.value);

                setPhoneCodes(codes);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch phone codes:", error);
                setError("Failed to load country codes.");
                setLoading(false);
            }
        };

        fetchPhoneCodes();
    }, []);

    const handleChange = (selectedOption) => {
        setSelectedCode(selectedOption);
        const countryName = selectedOption.label.split(" (")[0]; 

        setSelectedcountryname(countryName);
        console.log("Selected Code:", selectedOption.value);
    };

    return (
        <div className='phone-login-container'>
             <br/>  
            <h3>Sign in to your account</h3>
        
            <a>Login or create an account</a>
            <br/> 
            <br/>

            <Select 
                className='codelist-input' 
                options={phoneCodes} 
                placeholder="Select country code"
                onChange={handleChange}  
            />
            
            <div className='phone-login-div'>
                <input 
                    className='phone-input' 
                    maxLength={14} 
                    type="tel" 
                    placeholder="Phone number" 
                    value={phone} 
                    onChange={(e) => setPhone(e.target.value)} 
                />
                
            </div>
            <button className='phone-login-button' onClick={sendOTP}>
                  {loading ? <Lodinging /> : "Send OTP"}
                
                </button>
            <div id="recaptcha-container"></div>
            <div className='otp-body-div' style={{ display: 'none' }}>
            <div className='phone-login-div'>
                <input 
                    className='otp-input' 
                    type="text" 
                    placeholder="Enter OTP" 
                    value={code} 
                    maxLength={6}
                    onChange={(e) => setCode(e.target.value)} 
                />
                </div>
            
            <button className='otp-Verify_button' onClick={verifyCode}>
            Verify OTP
            </button>
            </div>
            {error && <a style={{ color: "red" }}>{error}</a>}
            <div  className='trams-div'>
            <div name="terms_of_service">By Get Started , you agree to the <a href='https://callqr.com/terms-of-service'>terms of service</a>.</div>
            <div name="privacy_policy">The <a href='https://callqr.com/privacy-policy'>Privacy Policy</a> explains how we handle your information.</div>
            </div>
        </div>
    );
}
