import './App.css';
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Header from './body_roots/header';
import Footer from './body_roots/footer';
import Home from './pages/home';
import Contactus from './pages/contact-us';
import DownloadPage from './pages/download';
import Termsofservice from './pages/terms-of-service';
import Helpcenter from './pages/help-center';
import Privacypolicy from './pages/privacy-policy';
import Disclaimer from './pages/disclaimer';
import Sitemap from './pages/sitemap';
import Forbusiness from './pages/for-business';
import Price from './pages/price';
import Features from './pages/features';
import Design from './pages/design';
import Security from './pages/security';
import Userguide from './pages/user_guide';
import Shorturl from './design_root/short-url';
import Order from './data_pages/order';
import Settings from './profile_roots/settings';
import Careers from './pages/careers';
function App() {
  const [location, setLocation] = useState({
    lat: null,
    lon: null,
    address: "Fetching...",
    city: "",
    state: "",
    country: "",
    zipcode: ""
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      const geoWatcher = navigator.geolocation.watchPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch address details from Google Geocoding API
          const apiKey = "AIzaSyBBrDds_05fvxejfzmm5gm4ywDQIlEO6E4"; // 🔴 Replace with your Google Maps API Key
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

          try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.results.length > 0) {
              const addressComponents = data.results[0].address_components;
              const formattedAddress = data.results[0].formatted_address;

              let city = "";
              let state = "";
              let country = "";
              let zipcode = "";

              addressComponents.forEach((component) => {
                if (component.types.includes("locality")) city = component.long_name;
                if (component.types.includes("administrative_area_level_1")) state = component.long_name;
                if (component.types.includes("country")) country = component.long_name;
                if (component.types.includes("postal_code")) zipcode = component.long_name;
              });

              const addressData = {
                lat: latitude,
                lon: longitude,
                address: formattedAddress,
                city,
                state,
                country,
                zipcode
              };

              // Update state
              setLocation(addressData);

              // Store in cookies
              Cookies.set("lat", latitude);
              Cookies.set("lon", longitude);
              Cookies.set("address", formattedAddress);
              Cookies.set("city", city);
              Cookies.set("state", state);
              Cookies.set("country", country);
              Cookies.set("zipcode", zipcode);
            }
          } catch (error) {
            console.error("Error fetching address:", error);
          }
        },
        (error) => console.error("Error getting location:", error),
        { enableHighAccuracy: true, maximumAge: 0 }
      );

      return () => navigator.geolocation.clearWatch(geoWatcher);
    } else {
      console.error("Geolocation is not supported.");
    }
  }, []);

  return (
    <div className="App">
      <Header />
      <div className='app_header_height'></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/short-url" element={<Shorturl />} />
        <Route path="/order" element={<Order />} />
        <Route path="/features" element={<Features />} />
        <Route path="/price" element={<Price />} />
        <Route path="/design" element={<Design />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/for-business" element={<Forbusiness />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-of-service" element={<Termsofservice />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/security" element={<Security />} />
        <Route path="/user_guide" element={<Userguide />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/careers" element={<Careers />} />

        <Route path="/:id" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
