import React, { useState, useRef, useEffect } from 'react';
import { useCallback } from "react";
import Cookies from 'js-cookie';
import { db } from "../firebase"; 
import './order.css';
import { FaLink, FaCarSide, FaRegIdBadge } from "react-icons/fa6";
import { useNavigate,useSearchParams } from "react-router-dom";
import { BsCalendar2Event } from "react-icons/bs";
import Lodinging from "../components/lodinging";  
import SwitchComponent from "../components/SwitchComponent";
import { QRCodeCanvas } from "qrcode.react";
import { CiSaveDown2 } from "react-icons/ci";
import { BsCartPlus } from "react-icons/bs";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";
 
 
export default function Order() {
  const navigate = useNavigate();
    const [isOn, setIsOn] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const qrRef = useRef(null); 
    const [qrCodeValue, setQrCodeValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [Productsvalue, setProductsvalue] = useState("");
    const [TitleValue, setTitleValue] = useState("");
    const [priceValue, setPricevalue] = useState(""); 
    const [ValdityValue, setValdityvalue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState("");


    const [searchParams] = useSearchParams();
  const puid = searchParams.get("puid");
  useEffect(() => {
    if (!puid) return; // ✅ Prevent Firestore error if puid is missing

    const fetchUserData = async () => { 
        try {
            const userRef = doc(db, "products", puid);
            const docSnap = await getDoc(userRef); 
            
            if (docSnap.exists()) {
                console.log("Firestore Data:", docSnap.data()); // ✅ Debugging
                setTitleValue(docSnap.data().title || "No description available");
                setPricevalue(docSnap.data().price || "0");
        
                setValdityvalue("Valdity: "+docSnap.data().validity +" "+docSnap.data().validity_liable || "No description available");
                setDescriptionValue(docSnap.data().description || "No description available");
            } else {
                console.warn("Document does not exist");
                setDescriptionValue("No data found");
            }

           
    document.title = `${docSnap.data().title} | Purchase Order `;
  
        } catch (error) {
            console.error("Error fetching data:", error);
            setDescriptionValue("Error loading description");
        }
    };

    fetchUserData(); 
}, [puid]); // ✅ Dependency array

  return (
    <div className="container_roots">
   
      <Helmet>
 
        <meta name="description" content="Order custom QR codes with CallQR. Personalize QR codes for business, marketing, and events. Get high-quality downloadable QR codes instantly." />
        <meta name="keywords" content="Order QR Code, Buy Custom QR Codes, QR Code for Business, Personalized QR Generator, Custom QR for Marketing, Purchase QR Tags, CallQR Order, QR Code Printing, Branded QR Codes, QR Code for Packaging" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Order QR Codes | CallQR | Customize & Purchase QR Codes"/>
        <meta property="og:description" content="Order custom QR codes with CallQR. Personalize QR codes for business, marketing, and events. Get high-quality downloadable QR codes instantly."/>
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/order" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta property="og:dateModified" content="2025-04-03" />
      </Helmet> 
      <div className="container_size">
      <h1 className="text-3xl font-bold mb-4">{`${TitleValue}`}</h1>
        <p className="text-lg mb-6">Create and purchase personalized QR codes for your business or event.</p>
        <input type="text" placeholder="Enter details for your QR code" className="border rounded p-2 w-3/4" />
        <button className="bg-green-500 text-white p-2 rounded ml-2">Order Now</button>
      </div>
</div>
    );
}
