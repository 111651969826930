import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { db } from "../firebase"; 
import Logo from '../assets/callqr_logo.png';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

import { doc, getDoc } from "firebase/firestore";
import { Helmet } from "react-helmet"; 
import ButtonWithImage from '../components/ButtonWithImage';
import './settings.css';

export default function Settings() {
  const [accountguid, setAccount] = useState(null);
  const [loginisOpen, loginsetIsOpen] = useState(false);
  const [accountname, setAccountName] = useState(null);
  const [photourl, setUserPhotoUrl] = useState(null);
  const navigate = useNavigate();  // Ensure usage if needed

  useEffect(() => {
    const fetchUserData = async () => {
      const existingUser = Cookies.get("guid");
      if (existingUser) {
        try {
          const userRef = doc(db, "account", existingUser);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            setAccountName(docSnap.data().name || "User");  
            setUserPhotoUrl(docSnap.data().photo || "/default-profile.png");
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className="container_roots">
      <Helmet>
        <title>Your Profile Settings - CallQR</title>
        <meta name="description" content="CallQR.com | Manage in and out entries effortlessly! Create parking cards, track caller locations, and view verified details. Use QR tags for ID cards, event cards, business tools, membership, attendance, and more. Download CallQR for secure, spam-free calls!" />
        <meta name="keywords" content="CallQR, secure communication, vehicle parking, QR tags, event cards, business tools, membership, attendance, spam-free calls" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Your Profile Settings - CallQR" />
        <meta property="og:description" content="CallQR.com offers fast, secure, and easy communication. Track caller locations, view verified details, and share contact info seamlessly with a QR Tag. Download CallQR today for safe, spam-free calls." />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
      </Helmet>

      <div className="settings_root">
        <div className="header-text">Your profile info in CALLQR</div>
        <b>Manage Your Personal Information</b>
        <p>Your personal information helps others reach you easily and improves your CallQR experience. You can choose what details to share and manage your profile settings.</p>

        <div className="title-text">Your Information Summary</div>
        <table>
          <tbody>
            <tr>
              <td className="table_settings">
                <a>Profile picture</a>
                <a className='name_tabel'>Profile picture</a>
                <img 
                  className="Profile_settings" 
                  title={accountname || "User"}  
                  src={photourl || "/default-profile.png"} 
                  alt="User Profile" 
                />
              </td>
            </tr>
            <tr>
              <td className="table_settings">
                <a>Name</a> <b className='name_tabel'>{accountname || "User"}</b>
                <a></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
