import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./home_data.css";
import { MdOutlineAddBusiness } from "react-icons/md";
import Homedataad from './home_data_ad'
import Homedataproducts from './home_data_products';
import Homedatainformation from './home_data_information';
import Homedatascannedalert from "./home_data_scanned_alert";
import Homedatawhoitwork from "./home_data_how_it_work";
import Ourfounders from "./our_founders";

import Designqrcode from "../design_root/design-qrcode";

export default function Homedata() {
  
 
  return (
    <div >
      <Helmet>
      <title>Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly</title>
    <meta name="description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!" />
    <meta name="keywords" content="QR Code Generator, Free QR Codes, Generate QR Online, Custom QR Codes, QR Creator, QR Code for Business, Scan QR Instantly, CallQR App, Parking QR Codes, Event QR Tags, ID Card QR, QR for Membership, QR Code for Website, QR Code for Payments" />

        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly"/>
        <meta property="og:description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!"/>
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta property="og:dateModified" content="2025-04-03" />
      </Helmet>
      <Designqrcode/>
      
      <div> 
         
      <Homedataad />    
      <Homedatainformation/>
      <Homedataproducts/>
      <Homedatascannedalert/>
      <Homedatawhoitwork/>
      <Ourfounders/>
      </div>
    </div>
  );
}
